import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/43f77cd94313c2a6e5626889186f0ecf/92e00/Morne_Sulazar_Stillwater_is_a_man_smoking_a_pipe_50572bc7-4633-4f06-b52d-ce3f91579737.png",
            "srcSet": ["/static/43f77cd94313c2a6e5626889186f0ecf/5ff7e/Morne_Sulazar_Stillwater_is_a_man_smoking_a_pipe_50572bc7-4633-4f06-b52d-ce3f91579737.png 375w", "/static/43f77cd94313c2a6e5626889186f0ecf/92e00/Morne_Sulazar_Stillwater_is_a_man_smoking_a_pipe_50572bc7-4633-4f06-b52d-ce3f91579737.png 511w"],
            "width": "400px",
            "className": "right center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Sulazar Stillwater`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p><strong parentName="p">{`Sulazar Stillwater`}</strong>{` is a `}<a parentName="p" {...{
        "href": "/Fade",
        "title": "Fade"
      }}>{`Fade`}</a>{`, currently employed as a diver working odd jobs in `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, mainly for the `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` in the `}<a parentName="p" {...{
        "href": "/Bathysward",
        "title": "Bathysward"
      }}>{`Bathysward`}</a>{`.`}</p>
    <h2>{`Severing`}</h2>
    <p>{`Before becoming a Fade, Sulazar was an Acronist working closely with `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin Fane`}</a>{` at the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{`. At some point, he came across something that caused him to voluntarily sever his Thread. It is unknown at this time why, but they had a following out after this, leading Sulazar to find a new job working at the `}<a parentName="p" {...{
        "href": "/Bathysward",
        "title": "Bathysward"
      }}>{`Bathysward`}</a>{`.`}</p>
    <h2>{`Events of Enzo Quint's Dissapearance`}</h2>
    <p>{`After ten years apart, `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin Fane`}</a>{` reached out to Sulazar to help on a case that had been brought to the institute: the dissapearance of `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{`, due the fact that a `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` appeared on the `}<a parentName="p" {...{
        "href": "/The%20Sixth%20Tinwheel",
        "title": "The Sixth Tinwheel"
      }}>{`The Sixth Tinwheel`}</a>{`, seemingly revealing that Enzo was kidnapped by one the Msanti, and Sulazar's close involvement with the `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` due to his work at the Bathysward.`}</p>
    <p>{`Sulazar become involved with the investigation of the case, joining `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen Quint`}</a>{`, `}<a parentName="p" {...{
        "href": "/Arlo%20Livingston",
        "title": "Arlo Livingston"
      }}>{`Arlo Livingston`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian Cullpepper`}</a>{`. He was involved with the robbery at the `}<a parentName="p" {...{
        "href": "/Central%20Bank%20of%20Meripol",
        "title": "Central Bank of Meripol"
      }}>{`Central Bank of Meripol`}</a>{`, helping to dispatch `}<a parentName="p" {...{
        "href": "/Roz%20Daras",
        "title": "Roz Daras"
      }}>{`Roz Daras`}</a>{` with a piece of rebar.`}</p>
    <p>{`During the investigation, he was called back to the Bathysward by `}<a parentName="p" {...{
        "href": "/Henry%20Wulverstone",
        "title": "Henry Wulverstone"
      }}>{`Henry Wulverstone`}</a>{`, to investigate bathysphere wreckage that had been discovered after a number of ships went missing a few months ago. Sulazar, alone, visited the wreckage, only to discover that the entire crew had been somehow killed save for one unidentified engineer that Sulazar discovered in the bridge of the ship. The engineer was wreathed in a black mist and had missing eyes, calling out to Sulazar, who managed to escape the wreckage unscathed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      